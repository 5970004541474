<template>
	<section class="content">
		<div class="card card-primary">
			<div class="card-header d-flex align-items-center">
				<h3 class="card-title mr-auto">Form Data</h3>
				<button class="btn btn-light text-warning font-weight-bold" @click.prevent="downloadTemplate">
					<i class="fa fa-download"></i> Unduh Template
					<span v-if="loadingDownload" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
				</button>
			</div>
			<!-- /.card-header -->
			<!-- form start -->
			<form role="form" @submit="submitForm">
				<div class="card-body">
					<p v-if="errors.length">
						<b>Please correct the following error(s):</b>
						<ul>
							<li v-for="error in errors">{{ error }}</li>
						</ul>
					</p>
					<div class="row">
						<div class="col-md-6 form-group">
							<label class="control-label">Skenario Jurnal</label>
							<select v-model="form.skenario" class="form-control">
								<option value="2">2 (Jurnal Pickup NonCOD)</option>
								<option value="3">3 (Jurnal Retur NonCOD)</option>
								<option value="4">4 (Jurnal Klaim RTK Disetujui)</option>
								<option value="5">5 (Jurnal Klaim NonRTK Disetujui)</option>
								<option value="6">6 (Jurnal Pickup COD)</option>
								<option value="7">7 (Jurnal Rekon COD)</option>
								<option value="9">9 (Jurnal Retur Diserahkan COD)</option>
								<option value="10">10 (Jurnal Retur Diterima COD)</option>
								<option value="11">11 (Jurnal Klaim RTK Disetujui COD)</option>
								<option value="12">12 (Jurnal Klaim NonRTK Disetujui COD)</option>
							</select>
						</div>
						<div class="col-md-6 form-group">
							<label class="control-label">File Kode</label>
							<form action="/target" ref="dzone" class="dropzone card m-0" style="border-style: dashed;min-height: fit-content;">
								<div class="overlay" v-if="loadingUpload">
									<div class="spinner-border" role="status">
										<span class="sr-only">Loading...</span>
									</div>
								</div>
								<div class="dz-message text-muted m-0" data-dz-message><h4><div><i class="fa fa-upload"></i></div><span>Upload file Anda disini</span></h4></div>
							</form>
							<span class="text-muted" v-if="!form.msg ? false : true">{{ form.msg }}</span>
						</div>
						<div class="col-md-4 form-group">
							<input id="status" v-model="form.reverse" type="checkbox"/>
							<label for="status">Jurnal Balik</label>
						</div>
					</div>
				</div>
					<!-- /.card-body -->
					<div class="card-footer">
						<button type="button" class="btn btn-secondary" v-on:click="submitForm">Proses</button>					
						</div>
			</form>
		</div>
	</section>
	<!-- /.content -->
</template>
<script>
import { authFetch } from '@/libs/hxcore';
import $ from 'jquery';
import Dropzone from "dropzone";
import "dropzone/dist/dropzone.css";
export default {
	name: 'Test',
	data() {
		return {
			errors: [],
			countries: [{label: 'Canada', code: 'ca'}, {label: 'Indonesia', code: 'id'}],
			form: {
				skenario: '',
				file: {},
				msg: undefined
			},
			loadingDownload: false,
			loadingUpload: false,
		}
	},
	components: {
	},
	mounted() {
		// Upload Excel
		Dropzone.autoDiscover = false;
		let myDropzone = new Dropzone(this.$refs.dzone, {
			uploadMultiple: false, maxFiles: 1,
			createImageThumbnails: false,autoProcessQueue: false,
			url: 'getMeSomeUrl',
			accept: function(file, done) {
				this.removeFile(file);
			},
			success: function(file)
			{
				this.removeFile(file);
			}
		});
		myDropzone.on("addedfile", file => {
			this.loadingUpload = true;
			this.form.file = file;
			console.log(file);
			setTimeout(() => {
				this.loadingUpload = false;
				this.form.msg = file.name;
			}, 2000);
		});
		myDropzone.on("maxfilesexceeded", function (file) {
			myDropzone.removeAllFiles();
		});
		$('.loading-overlay').removeClass('show');
	},
	methods: {
		downloadTemplate: function()
		{
			this.loadingDownload = true;
			authFetch("/file/aset/template_tracking", {
				method: 'GET',
			})
			.then(response => response.blob())
			.then(blob => {
				this.loadingDownload = false;
				var url = window.URL.createObjectURL(blob);
				var a = document.createElement('a');
				a.href = url;
				a.download = "Template Generate Jurnal Batch.xlsx";
				document.body.appendChild(a);
				a.click();
				a.remove();	 
			});

		},
		submitForm: function(e) {
			var data = new FormData();
				data.append('id', this.form.file);
				data.append('skenario', this.form.skenario);

			authFetch('/examples/test/batch', {
				method: 'POST',
				body: data,
				headers: {
					'Content-Type': null,
				},
			})
			.then(res => {
				if (res.status === 201) {
					alert('OK');
				} else if (res.status === 400) {
					//let errorResponse = await res.json();
					//this.errors.push(errorResponse.error);
				}
				return res.json();
			})
			.then(js => {
				for (var key in js.success) {
					var msg = 'Proses berhasil';
					if (js.success[key].status == false) {
						msg = 'Proses gagal';
					}
				}
				alert(msg);
			});
			this.errors = [];
			e.preventDefault();
		},
	}
}
</script>
<style>
.twitter-typeahead {
	display: block !important;
}
</style>